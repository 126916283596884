import styled from 'styled-components'
import { theming } from '@staccx/bento'
import { i18nInstance } from '@staccx/i18n'
import { Card } from '../Card'
import Car2 from '../../../../icons/Car2'
import ChevronRight2 from '../../../../icons/ChevronRight2'

export const BudgetSelectionsList = ({ budgetSelections }) => {
  return (
    <ListContainer>
      {budgetSelections.map((flow) => {
        const { flowId } = flow
        const { carBudgetNo, driver } = flow.data.budgetData
        const carMake = flow.data.budgetData.carMake.toUpperCase()
        const carModel = flow.data.budgetData.carModel.toUpperCase()

        return (
          <Card
            key={flow._id}
            icon={<Car2 />}
            href={`/tasks/budget-selection/${flowId}`}
            title={`${carMake} ${carModel} - ${i18nInstance.translate(
              'BUDGET',
            )} ${carBudgetNo} ${driver ? ` - ${driver}` : ''}`}
            action={<ChevronRight2 />}
            isHoverable
          />
        )
      })}
    </ListContainer>
  )
}

const ListContainer = styled.div`
  margin-bottom: ${theming.spacing.medium()};
`
