import React, { useMemo, useState, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { Heading, SmallHeading } from '../../../components/text/Headings'
import Container from '../../../components/Container'
import LostCard from '../components/LostCard'
import { TranslatedText } from '@staccx/i18n'
import AnchorButton from '../components/AnchorButtons'
import ContactPerson from '../components/ContactPerson'
import { useCarContext } from '../../../context/CarProvider'
import { useDriverContext } from '../../../context/DriverProvider'
import { useChosenCompany, useFeatureToggles, useUser } from '../../../context/UserProvider'
import { useNotifications } from '../hooks/useNotifications'
import { useQuery } from 'react-query'
import { newsFromSanity } from '../../../api/api'
import { useI18nContext } from '../../../context/I18nProvider'
import NewsArticleCard from '../components/NewsArticleCard'
import { useNavigate } from 'react-router-dom'
import NewsArticle from '../components/NewsArticle'
import Close from '../../../icons/Close'
import Dialog from '../../../components/modal/Dialog'
import { Link } from 'react-router-dom'
import customToast from '../../carConfiguration/components/toast/customToast'
import Toast from '../../carConfiguration/components/toast/Toast'
import NotificationView from '../components/NotificationView'
import { CloseButton } from '../../../theme/Theme.Button'
import NewEmployee from '../../contacts/components/NewEmployee'
import { fetchSalesPerson } from '../../../api/api'
import { useBudgetSelections, usePendingSignings } from '../../../context/SigningProvider'
import { LostCardWrapper } from '../components/LostCardWrapper'

const Home = () => {
  const [showNewDriverModal, showNewDriverModalSet] = useState(false)
  const [selectedNewsArticle, selectedNewsArticleSet] = useState(null)
  const [newsPage, newsPageSet] = useState(0)
  const [showOrderCarDialog, showOrderCarDialogSet] = useState(false)

  const navigate = useNavigate()
  const user = useUser()
  const company = useChosenCompany()
  const featureToggles = useFeatureToggles()

  const { updateCars, allCars } = useCarContext()
  const { pendingSignings } = usePendingSignings(user?.user.id)
  const { budgetSelections } = useBudgetSelections(user?.user.id)
  const notifications = useNotifications(pendingSignings, budgetSelections, allCars)

  const { updateDrivers } = useDriverContext()
  const { data: news } = useQuery(['news'], () => newsFromSanity())
  const { language } = useI18nContext()
  const formattedNews = useMemo(() => {
    if (!news) return []
    return news.map((newsItem) => ({
      heading: newsItem?.newsHeading?.[language],
      id: newsItem?._id,
      ingress: newsItem?.newsIngress?.[language],
      body: newsItem?.newsBody?.[language],
      image: newsItem.newsImage,
      author: newsItem.newsAuthor,
      updatedAt: new Date(newsItem._updatedAt)
    }))
  }, [news, language])

  const { data: salesPerson } = useQuery(['salesperson', company?.id], () => fetchSalesPerson(company?.id), {
    enabled: !!company?.id
  })

  const onEditDriverFinish = () => {
    queryClient.invalidateQueries('cars').then(() => console.log('Updating cars'))
    queryClient.invalidateQueries('drivers').then(() => console.log('Updating drivers'))
    queryClient.invalidateQueries('contacts').then(() => console.log('Updating contacts'))
  }

  const confirmLostCardOrder = useCallback(() => {
    return customToast.success(<TranslatedText i18nKey="ORDER_COMPLETED" />)
  }, [])

  const faultOrder = useCallback(() => {
    return customToast.error(<TranslatedText i18nKey="ORDER_COMPLETED_ERROR" />)
  }, [])

  return (
    <Container>
      <Wrapper>
        {user && (
          <div>
            <Heading>
              <TranslatedText i18nKey="WELCOME" />
              {user.user.name}!
            </Heading>
            {notifications.length > 0 && (
              <SmallHeading>
                <TranslatedText i18nKey="TASKS" />
              </SmallHeading>
            )}
          </div>
        )}
        {notifications.length > 0 && <NotificationView notifications={notifications} />}
        <Grid>
          <News>
            <TranslatedText i18nKey="NEWS">
              {(value) => (
                <SmallHeading level={2} variant="large">
                  {value}
                </SmallHeading>
              )}
            </TranslatedText>
            {formattedNews &&
              formattedNews
                .slice(newsPage, newsPage + 5)
                .map((article, i) => (
                  <NewsArticleCard
                    article={article}
                    onClick={selectedNewsArticleSet}
                    key={`article-${article?.heading}-${i}`}
                  />
                ))}
            {formattedNews?.length > 0 && (
              <NewsPagination>
                {newsPage !== 0 ? (
                  <div>
                    <TranslatedText i18nKey="PREVIOUS_PAGE">
                      {(value) => (
                        <SmallHeading onClick={() => newsPageSet(newsPage - 5)}>
                          {value}
                          ...
                        </SmallHeading>
                      )}
                    </TranslatedText>
                  </div>
                ) : (
                  <div />
                )}
                {formattedNews?.[newsPage + 1] ? (
                  <div>
                    <TranslatedText i18nKey="NEXT">
                      {(value) => (
                        <SmallHeading onClick={() => newsPageSet(newsPage + 5)}>
                          {value}
                          ...
                        </SmallHeading>
                      )}
                    </TranslatedText>
                  </div>
                ) : (
                  <div />
                )}
              </NewsPagination>
            )}
            <Dialog open={!!selectedNewsArticle} onOpenChange={selectedNewsArticleSet} width={'50vw'}>
              <CloseButton>
                <Close onClick={() => selectedNewsArticleSet(null)} />
              </CloseButton>
              <Content>{selectedNewsArticle && <NewsArticle article={selectedNewsArticle} />}</Content>
            </Dialog>
          </News>
          <ButtonsContainer>
            {featureToggles.customerCarConfiguration && (
              <div>
                {user?.user?.configuratorAccess ? (
                  <AnchorButton text={<TranslatedText i18nKey="ORDER_CAR" />} action={() => navigate('/car-order')} />
                ) : (
                  <Dialog
                    open={showOrderCarDialog}
                    onOpenChange={showOrderCarDialogSet}
                    trigger={
                      <AnchorButton
                        text={<TranslatedText i18nKey="ORDER_CAR" />}
                        action={() => showOrderCarDialogSet(true)}
                      />
                    }
                  >
                    <CloseButton type={'button'}>
                      <Close onClick={() => showOrderCarDialogSet(false)} />
                    </CloseButton>
                    <HelpDialogPadding>
                      <Heading>
                        <TranslatedText i18nKey="ACCESS_FOR_CAR_CONFIGURATION" />
                      </Heading>
                      <HelpLink to={'/help'}>
                        <ClickHereForHelpText>
                          <TranslatedText i18nKey="CLICK_HERE_CAR_CONFIG" />
                        </ClickHereForHelpText>
                      </HelpLink>
                    </HelpDialogPadding>
                  </Dialog>
                )}
              </div>
            )}
            {user?.user.roleType === 2 && (
              <NewEmployee
                showNewDriverModal={showNewDriverModal}
                showNewDriverModalSet={showNewDriverModalSet}
                updateDrivers={updateDrivers}
                updateCars={updateCars}
                onEditDriverFinish={onEditDriverFinish}
              />
            )}
            {featureToggles.meldSkade && <AnchorButton text={<TranslatedText i18nKey="REPORT_DAMAGE" />} />}
            <LostCardWrapper onOrder={confirmLostCardOrder} onFaultOrder={faultOrder}>
              <AnchorButton text={<TranslatedText i18nKey="LOST_CARD" />} />
            </LostCardWrapper>
            {salesPerson && <ContactPerson contactPerson={salesPerson} />}
            {featureToggles.dinKontaktperson && <ContactPerson />}
          </ButtonsContainer>
        </Grid>
      </Wrapper>
      <Toast limit={1} />
    </Container>
  )
}

export default Home

const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: var(--spacing-medium);
  @media (min-width: 1200px) {
    padding: 0;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-largePlus);
  height: 100%;
  padding-top: var(--spacing-large);
  @media (min-width: 1200px) {
    grid-template-columns: 2fr 1fr;
  }
`

const News = styled.div`
  display: grid;
  gap: var(--spacing-small);
  align-self: flex-start;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-small);
  white-space: nowrap;
  height: 100%;
`

const NewsPagination = styled.div`
  display: flex;
  justify-content: space-between;

  span {
    line-height: 2.5em;
    text-decoration: underline;
    color: var(--color-primary);
    cursor: pointer;
  }
`

const HelpDialogPadding = styled.div`
  padding: 0 0 10px 0;
  width: 100%;
`

const ClickHereForHelpText = styled.div`
  margin: 8px 0 0 0;
`
const HelpLink = styled(Link)`
  text-decoration: none;
`
const Content = styled.div`
  width: 100%;
  margin-top: var(--spacing-medium);
`
