import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Heading } from '../../../components/text/Headings'
import { StyledButton } from '../../../theme/Theme.Button'
import { StyledItemGroup } from '../../../theme/Theme.ItemGroup'
import { useCarContext } from '../../../context/CarProvider'
import DropDown from '../../../components/DropDownMenu'
import { TranslatedText } from '@staccx/i18n'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { sendAdditionalProductEmail } from '../../../api/flow'
import { useChosenCompany } from '../../../context/UserProvider'


import { usePostalPlace } from '../hooks/usePostalPlace'

const LostCard = ({ children, onOrder, onFaultOrder, user }) => {
  
  const company = useChosenCompany()
  const userAddress = user?.user?.contactDetails?.address || ''
  const userName = user?.user?.contactDetails?.name || ''
  const postCode = user?.user?.contactDetails?.postCode || ''
  const [open, openSet] = useState(false)
  const [chosenCar, chosenCarSet] = useState('')
  const [cardType, cardTypeSet] = useState('')
  const [address, setAddress] = useState(userAddress)
  const [customerName, setCustomerName] = useState(userName)
  const [postalCode, setPostalCode] = useState(postCode)
  const [postCity, setPostCity] = useState("")
  const [additionalComment, setAdditionalComment] = useState('')
  const { allCars } = useCarContext()

  const postalPlace = usePostalPlace(postalCode)

 

  const onOrderNewCard = async () => {

    const formatAddress = `${address}, ${postalCode} ${postCity}`
    const payload = {
      regNr: chosenCar?.registrationNumber,
      company: company?.name,
      productType: cardType?.Description,
      address: formatAddress,
      customerName: customerName === '' ? '---' : customerName,
      additionalComment: additionalComment,
    }


    try {
      await sendAdditionalProductEmail(payload)
      onOrder()
      openSet(false)
    } catch (e) {
      onFaultOrder()
      openSet(false)
    }
  }

  useEffect(() => {
    if(postalCode === ''){
      setPostCity("")
    }
  },[postalCode])

  useEffect(() => {
    if (postalPlace?.data?.result) {
      setPostCity(postalPlace?.data?.result)
    }
  }, [postalPlace])


  const disabled = chosenCar === '' || cardType === '' || address === '' || customerName === '' ||  postalCode.length !== 4 || postCity === "Ugyldig postnummer"

  return (
    <DialogPrimitive.Root open={open} onOpenChange={openSet}>
      <DialogPrimitive.Trigger asChild>{children}</DialogPrimitive.Trigger>
      <Overlay />
      <DialogContent onPointerDownOutside={(event) => event.preventDefault()}>
        <Grid>
         <p className='text-xl font-semibold'>Tapt drivstoffkort/ladebrikke</p>
          <div>
            <label className="font-semibold" htmlFor="regNo">
             Registreringsnummer
            </label>
            <DropDown
              placeholder="XX NNNNN"
              options={allCars}
              radius={`var(--borderRadius)`}
              label={<TranslatedText i18nKey="REG_NO" />}
              getOptionValue={(option) => `${option['registrationNumber']}`}
              getOptionLabel={(option) => `${option['registrationNumber']}`}
              onChange={(car) => {
                chosenCarSet(car)
                cardTypeSet('')
              }}
            />
          </div>
          <div>
             <label className="font-semibold" htmlFor="productType">
             Produkttype
            </label>
            <DropDown
              options={chosenCar?.additionalProducts}
              placeholder={<TranslatedText i18nKey="CHOOSE" />}
              value={cardType || ''}
              radius={`var(--borderRadius)`}
              getOptionValue={(option) => `${option['AgreementCode']}`}
              getOptionLabel={(option) => `${option['Description']}`}
              onChange={(type) => cardTypeSet(type)}
            />
          </div>
           <div className="flex flex-col gap-1">
            <label className="font-semibold" htmlFor="customerName">
              Navn
            </label>
            <input
              className="p-4 text-primaryLight border-[1.5px] border-gray-300 rounded-lg"
              id="customerName"
              value={customerName}
              onChange={(e) => {
                setCustomerName(e.target.value)
              }}
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="font-semibold" htmlFor="address">
              Adresse
            </label>
            <input
              className="p-4 text-primaryLight border-[1.5px] border-gray-300 rounded-lg"
              id="address"
              value={address}
              onChange={(e) => {
                setAddress(e.target.value)
              }}
            />
          </div>
          <div className='flex flex-row items-center justify-between [&>*]:w-full gap-2'>
             <div className="flex flex-col gap-1">
            <label className="font-semibold" htmlFor="postCode">
              Postnummer
            </label>
            <input
              className="p-4 text-primaryLight border-[1.5px] border-gray-300 rounded-lg"
              id="postCode"
              value={postalCode}
              maxLength={4}
              onChange={(e) => {
                setPostalCode(e.target.value)
              }}
            />
          </div>
           <div className="flex flex-col gap-1">
            <label className="font-semibold" htmlFor="postCity">
              Poststed
            </label>
            <input
              className="p-4 disabled:bg-gray-200 text-primaryLight border-[1.5px] border-gray-300 rounded-lg"
              id="postCity"
              disabled
              value={postCity}
              onChange={(e) => {
                setPostCity(e.target.value)
              }}
            />
          </div>
          </div>
           <div className="flex flex-col gap-1">
            <label className="font-semibold" htmlFor="additionalComment">
              Kommentar
            </label>
            <textarea
              className="p-4 h-[100px] text-primaryLight border-[1.5px] border-gray-300 rounded-lg"
              id="additionalComment"
              placeholder='Skriv en kommentar'
              value={additionalComment}
              onChange={(e) => {
                setAdditionalComment(e.target.value)
              }}
            />
          </div>
          <StyledItemGroup>
            <StyledButton
              onClick={() => onOrderNewCard()}
              disabled={disabled}
            >
              <TranslatedText i18nKey="ORDER" />
            </StyledButton>
            <DialogPrimitive.DialogClose asChild>
              <StyledButton
                onClick={() => {
                  cardTypeSet('')
                  chosenCarSet('')
                }}
              >
                <TranslatedText i18nKey="CLOSE" />
              </StyledButton>
            </DialogPrimitive.DialogClose>
          </StyledItemGroup>
        </Grid>
      </DialogContent>
    </DialogPrimitive.Root>
  )
}

export default LostCard

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  gap: var(--spacing-medium);
`
const DialogContent = styled(DialogPrimitive.Content)`
  max-width: 90%;
  width: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  background-color: var(--color-white);
  border: var(--spacing-microMinus) solid var(--color-line);
  border-radius: var(--spacing-small);
  padding: var(--spacing-medium);
  box-shadow: 0 0 var(--spacing-small) rgba(0, 0, 0, 0.12);
`
const Overlay = styled(DialogPrimitive.Overlay)`
  background-color: rgb(248, 248, 248, 0.8);
  position: fixed;
  inset: 0;
`
