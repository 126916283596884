import { useQuery } from 'react-query'

export function usePostalPlace(postCode: string) {
  const data = useQuery({
    queryKey: ['postalPlace', postCode],
    queryFn: async () => {
      const res = await fetch(
        `https://api.bring.com/shippingguide/api/postalCode.json?pnr=${postCode}`,
      )
      return res.json()
    },
    enabled: postCode?.length === 4,
  })

  return data
}
