import { useUser } from '../../../context/UserProvider'
import LostCard from './LostCard'

export function LostCardWrapper({ children, onOrder, onFaultOrder }) {
  const user = useUser()
  if (!user) {
    return null
  }

  return (
    <LostCard user={user} onOrder={onOrder} onFaultOrder={onFaultOrder}>
      {children}
    </LostCard>
  )
}
